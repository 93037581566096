import { SharedService } from 'src/app/shared.service';
import { Injectable } from '@angular/core';
import { GenericResponse } from 'projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Generic/af-generic-response/af-genericResponse';
import { EncryptionService } from 'projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Security/Encryption/encryption.service';
import { DataService } from 'projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Services/data-service/data.service';
import { environment } from 'src/environments/environment';
import { EquipmentTypeViewModel } from '../BusinessEntities/equipment-type-view-model';
import { PackageGroupListRequestModel } from '../BusinessEntities/group-list-request-model';
import { PackageListViewModel } from '../BusinessEntities/package-list-view-model';
import { PackagePortalDashboardDataViewModel } from '../BusinessEntities/package-portal-dashboard-data-view-model copy';
import { PackageTagsMasterViewModel } from '../BusinessEntities/package-tags-master-view-model';
import { PackageViewRecord } from '../BusinessEntities/package-view-record';
import { TyreTypesViewModel } from '../BusinessEntities/tyre-type-view-model';
import { ChassisTypeModel } from '../BusinessEntities/chassis-type-view-model';
import { PackagePortalGridParamatersViewModel } from '../BusinessEntities/package-portal-grid-paramaters-view-model';

@Injectable({ providedIn: 'root' })
export class PackagePortalDashboardDataService {

    baseURL = environment.packageToolApiUrl;
    controllerName="";
    constructor(
        private dataProvider: DataService,
        private encryptionService: EncryptionService,
        private sharedService:SharedService
    ) { }

    assignControllerName(){
      if(this.sharedService.isExternalUser) {
        this.controllerName = "PackagePortalExternal";
      } else{
        this.controllerName = "PackagePortalInternal";
      }
    }

    GetPackagePortalDashboardData(dealerId: string) {
      this.assignControllerName();
        return this.dataProvider
            .Get<GenericResponse<PackagePortalDashboardDataViewModel>>(
                this.baseURL + this.controllerName + '/GetPackagePortalDashboardData/' + this.encryptionService.encryptURLParameters(dealerId.toString())).toPromise();
    }

    GetPackages(packagePortalGridParamatersViewModel: PackagePortalGridParamatersViewModel) {
      this.assignControllerName();
        return this.dataProvider
            .GetAllwithHeaders<GenericResponse<PackageListViewModel[]>>(
                this.baseURL +  this.controllerName + '/GetPackageList', packagePortalGridParamatersViewModel).toPromise();
    }
    GetGroupList(packgeGroupModel: PackageGroupListRequestModel) {
      this.assignControllerName();
        return this.dataProvider
            .Post<GenericResponse<EquipmentTypeViewModel[]>>(
                this.baseURL +  this.controllerName + '/GetGroupList/', packgeGroupModel).toPromise();
    }
    AddVisitRecord(packageViewRecord: PackageViewRecord) {
      this.assignControllerName();
        return this.dataProvider.Post<any>(
            this.baseURL +  this.controllerName + '/AddVisitRecord',
            packageViewRecord
        ).toPromise();
    }

    public async GetDefaultTag() {
      this.assignControllerName();
        return await this.dataProvider.GetAll<GenericResponse<PackageTagsMasterViewModel>>(this.baseURL +  this.controllerName + '/GetDefaultTag').toPromise();
    }

    GetTyreTypeDataForPackagePortal() {
      this.assignControllerName();
        return this.dataProvider
            .Get<GenericResponse<TyreTypesViewModel>>(
                this.baseURL +  this.controllerName + '/GetTyreTypeDataForPackagePortal').toPromise();
    }

    public async GetChassisTypes(modelFamily: string) {
      this.assignControllerName();
        return this.dataProvider
            .Get<GenericResponse<ChassisTypeModel[]>>(
                this.baseURL +  this.controllerName + '/GetChassisTypes/' + this.encryptionService.encryptURLParameters(modelFamily)
            ).toPromise();
    }

    public async GetVehicleCode(globalSearchString: string) {
      this.assignControllerName();
        return this.dataProvider
            .Get<number>(
                this.baseURL +  this.controllerName + '/GetVehicleCode/' + this.encryptionService.encryptURLParameters(globalSearchString)
            ).toPromise();
    }

    public async GetTruckBrands() {
      this.assignControllerName();
        return this.dataProvider
        .Get<GenericResponse<string[]>>(
            this.baseURL +  this.controllerName + '/GetTruckBrands'
        ).toPromise();
    }
}
