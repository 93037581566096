import { Injectable } from "@angular/core";
import { GenericResponse } from "projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Generic/af-generic-response/af-genericResponse";
import { EncryptionService } from "projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Security/Encryption/encryption.service";
import { DataService } from "projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Services/data-service/data.service";
import { environment } from "src/environments/environment";
import { PackageDataViewModel } from "../BusinessEntities/package-data-view-model";
import { SharedService } from 'src/app/shared.service';

@Injectable({
  providedIn: 'root',
})
export class PackageDetailsDataService {
  baseURL = environment.packageToolApiUrl;
  controllerName="";
  constructor(private dataProvider: DataService, private sharedService: SharedService, private encryptionService: EncryptionService) {

  }

  assignControllerName(){
    if(this.sharedService.isExternalUser) {
      this.controllerName = "PackagePortalExternal";
    } else{
      this.controllerName = "PackagePortalInternal";
    }
  }

  GetPackageDataById(id: number) {
    this.assignControllerName();
    return this.dataProvider
      .Get<GenericResponse<PackageDataViewModel>>(
        this.baseURL + this.controllerName +'/GetPackageDetailById/' + this.encryptionService.encryptURLParameters(id.toString()), !this.sharedService.isExternalUser && this.sharedService.userDetails.defaultDealerId ? this.sharedService.userDetails.defaultDealerId : '764').toPromise();
  }
}
