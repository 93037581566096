import { ResponseCode, Culture, PageType, ErrorType } from 'projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Common/Af-bos-enums';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, UrlSegment, NavigationStart } from '@angular/router';
import { UserMenuRightsViewModel } from '../../Autofacets.Infrastructure.Common/view-model/UserMenuRightsViewModel';
import { UserDetails } from '../../Autofacets.Infrastructure.Common/Model/UserDetails';
import { GenericResponse } from '../../Autofacets.Infrastructure.Generic/af-generic-response/af-genericResponse';
import { UserService } from 'projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Services/user-service/user.service';
import { AfLoaderComponent } from '../../Autofacets.Infrastructure.Components/af-loader-component/af-loader.component';
import { TranslateService } from '@ngx-translate/core';
import { AfHelper } from '../../Autofacets.Infrastructure.Utility/Af-Helper/af-helper.service';
import { UserRoleMenu } from '../../Autofacets.Infrastructure.Common/Model/UserRoleMenu';
import { ToastrService } from 'ngx-toastr';
import { ChildRoutes, ChildRoutesExternal } from '../../Autofacets.Infrastructure.Common/Constant/route.constant';
import { SharedService } from 'src/app/shared.service';


@Injectable({
  providedIn: 'root'
})
export class BaseService {
  loaderRef: NgbModalRef;
  isLoading: boolean;
  userName: string;
  defaultMenu: UserRoleMenu;
  userDataTimeStamp: Date;
  isReload: boolean = false;
  currentLang: string;
  constructor(
    public modalService: NgbModal,
    public toastr: ToastrService,
    public router: Router,
    private userService: UserService,
    private sharedModule: SharedService,
    private config: NgbModalConfig,
    private translate: TranslateService,
    private afHelper: AfHelper

  ) {
    config.backdrop = 'static';
    config.keyboard = true;
    this.ManageNavigation();
  }

  userDetails: UserDetails;
  async getUserMenudetails() {

    await this.userService.GetUserDetails()
      .then((res: GenericResponse<UserDetails>) => {
        if (res.responseCode === ResponseCode.Success) {
          this.userDetails = res.responseObject;
        }
      }
      );
    return this.userDetails;
  }
  getCurrentUrlAccess(lang: string) {
    const urls = this.getCurrentUrlPaths(lang);
    return this.getCurrentMenuAccess(urls[0], urls[1]);
  }

  getPageType(lang: string) {
    const urls = this.getCurrentUrlPaths(lang);
    return PageType[Object.keys(PageType).find(key => key.toLowerCase() === urls[2].toLowerCase())];

  }
  getErrorType() {
    const urls = this.getCurrentUrlPaths();
    return ErrorType[Object.keys(ErrorType).find(key => key.toLowerCase() === urls[1].toLowerCase())];
  }

  actionExecutable(canAdd: boolean, canEdit: boolean, lang: string) {
    if (canAdd || canEdit) {
      return true;
    } else {
      this.routeUnAuthorizedAccess(lang);
    }
  }

  isModuleAccessible(module: string) {
    return this.userDetails.userModuleAccess.some(x => x.moduleName.toLocaleLowerCase() === module.toLocaleLowerCase());
  }

  isMenuAccessible(url: UrlSegment[]) {
    const actionType = url[2].path.toLowerCase();
    const currentUserMenuRights = this.getCurrentMenuAccess(url[0].path, url[1].path);
    let isAccessible = false;
    switch (actionType) {
      case 'add':
        isAccessible = currentUserMenuRights.canAdd;
        break;
      case 'edit':
        isAccessible = currentUserMenuRights.canEdit;
        break;
      case 'delete':
        isAccessible = currentUserMenuRights.canDelete;
        break;
      case 'edit-wo-olp':
      case 'edit-wo':
      case 'view-wo':
      case 'view':
      case 'list':
      case 'index':
      case 'usermanualindex':
      case 'reservecarrefund':
      case 'configuration':
      case 'dashboard':
      case 'scheduler':
        isAccessible = currentUserMenuRights.canView;
        break;
      case 'seperator':
        isAccessible = (currentUserMenuRights.canDelete);
        break;
      case 'viewonly':
        isAccessible = (currentUserMenuRights.canEdit || currentUserMenuRights.canAdd);
        break;
      case 'save':
        isAccessible = (currentUserMenuRights.canEdit || currentUserMenuRights.canAdd);
        break;
      case 'readonly':
        isAccessible = (currentUserMenuRights.canEdit || currentUserMenuRights.canAdd);
        break;
      case 'editdelete':
        isAccessible = (currentUserMenuRights.canEdit || currentUserMenuRights.canDelete);
        break;
    }
    return isAccessible;
  }
  public routeUnAuthorizedAccess(lang: string) {
    return this.router.navigate([lang, 'Error', 'UnAuthorized']);
  }
  public routeOfUnAuthorizedAccess(lang: string) {
    return this.router.parseUrl('/' + lang + '/Error/UnAuthorized');
  }
  public getCurrentUrlPaths(lang: string = null) {
    const url = this.router.routerState.snapshot.url.toLocaleLowerCase();
    const urls = url.replace('/en-us/', '')
      .replace('/nb-no/', '').split('/');
    return urls;
  }

  private getCurrentMenuAccess(areaName: string, menuName: string) {
    return this.getMenuRights(areaName.toLowerCase(), menuName.toLowerCase());
  }

  private getMenuRights(areaName: string, menu: string) {
    let menuRights: UserMenuRightsViewModel = {
      menuName: menu,
      canAdd: false,
      canEdit: false,
      canView: false,
      canDelete: false
    };

    if (this.userDetails
      .userRoleMenu
      .some(m => m.menuName.toLowerCase() === menu && m.areaName.toLowerCase() === areaName)) {
      menuRights = this.userDetails
        .userRoleMenu
        .filter(m => m.menuName.toLowerCase() === menu && m.areaName.toLowerCase() === areaName)[0];
    }
    let roledata = this.userDetails.userRoleMenu.filter(x => x.menuName.toLowerCase() == menu && x.areaName.toLowerCase() == areaName);
    if (roledata.length > 0) {
      menuRights.moduleName = roledata[0].moduleName
      menuRights.sectionType = roledata[0].sectionType
    }
    return menuRights;
  }

  showLoader(show: boolean) {
    if (show && !this.isLoading) {
      this.loaderRef = this.modalService.open(AfLoaderComponent, {
        windowClass: 'override-z-index-five'
      });
      this.isLoading = show;
    } else if (this.loaderRef && !show) {
      this.loaderRef.dismiss();
      this.isLoading = show;
    }
  }

  GetCurrentLanguage() {
    let url = window.location.href;
    if (url.indexOf('nb-NO') === -1 && url.indexOf('en-US') === -1) {
      return Culture.Norwegian;
    }
    return url.indexOf('nb-NO') !== -1 ? Culture.Norwegian : Culture.English;
  }

  DownLoadFile(data: any, extension: string, name: string) {
    let type: string;
    type = this.GetContentTypeDownload(extension);
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const fileLink = document.createElement('a');
    fileLink.href = url;
    fileLink.download = name;
    fileLink.click();
  }

  DownLoadFileWithContentType(data: any, type: string, name: string) {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const fileLink = document.createElement('a');
    fileLink.href = url;
    fileLink.download = name;
    fileLink.click();
  }



  private GetContentTypeDownload(extension: string) {
    let contentType: string;
    if (extension === '.xlsx') {
      contentType = 'application/ms-excel';
    } else if (extension === '.pdf') {
      contentType = 'application/pdf';
    } else if (extension === '.csv') {
      contentType = 'text/csv';
    }
    return contentType;
  }

  SetBreadCrumbOnHardRefress(urls: UrlSegment[]) {
    if (urls[0] && urls[1]) {
      let userAccess = this.getCurrentMenuAccess(urls[0].toString(), urls[1].toString());
      if (userAccess) {
        this.translate.get([userAccess.menuName, userAccess.moduleName, userAccess.sectionType])
      }
    }
  }

  GetReportTitle(lang: string) {
    const urls = this.getCurrentUrlPaths(lang);
    let fileName = '';
    if (urls[0] && urls[1]) {
      let userAccess = this.getCurrentMenuAccess(urls[0].toString(), urls[1].toString());
      if (userAccess) {
        this.translate.get([userAccess.menuName, userAccess.moduleName, userAccess.sectionType])
          .subscribe(translations => {
            fileName = translations[userAccess.menuName];
          });
      }
    }
    return fileName;
  }

  private ManageNavigation() {
    this.router.events
      .pipe()
      .subscribe(async (event) => {
        if (event instanceof NavigationStart) {
          if (event.url === '' || event.url === '/' || event.url.includes('id_token')) {
            this.NavigateToDefaultPage();
          } else {
            this.isReload = true;
          }
        }
      });
  }

  private NavigateToDefaultPage() {
    if (!this.sharedModule.isExternalUser) {
      this.router.navigate([ChildRoutes.LandingDefaultRoute]);
    } else {
      this.router.navigate([ChildRoutesExternal.LandingDefaultRoute]);
    }
  }
}
