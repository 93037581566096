import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from 'projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Services/data-service/data.service';
import { GenericResponse } from 'projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Generic/af-generic-response/af-genericResponse';
import { UserDetails } from 'projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Common/Model/UserDetails';

@Injectable({ providedIn: 'root' })
export class UserDataService {
  private baseURL = environment.packageToolApiUrl;

  constructor(
    private dataService: DataService
  ) { }

  public GetUserDetails() {
    return this.dataService
      .GetAll<GenericResponse<UserDetails>>(this.baseURL + 'PackagePortal/GetLoggedInUserDetails')
      .toPromise();
  }

  public GetToken() {
    return this.dataService
      .Get<string>(this.baseURL + 'Token/GetToken')
      .toPromise();
  }
}
