import { Injectable } from "@angular/core";
import { SecurityKeyConst } from "projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Common/Constant/global.constant";
import { DataService } from "projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Services/data-service/data.service";
import { environment } from "src/environments/environment";
import { SharedService } from "./shared.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
  baseCoreApiURI = environment.genericApiURL;
  constructor(private dataProvider: DataService, private sharedService:SharedService) { }
  async authenticate() {
      try {
          let tokenInfo = await this.dataProvider.GetWithBasicAuth<any>(this.baseCoreApiURI + 'ClientToken/GetAuthToken', SecurityKeyConst.authApplicationSource).toPromise();
          if (tokenInfo) {
              this.sharedService.accessToken = tokenInfo.responseObject.accessToken;
              this.sharedService.isSetOAuthToken = true;
              return true;
          }
          return false;
      } catch (err) {
          console.error(err);
          return false;
      }
  }
}
