import { DataService } from 'projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Services/data-service/data.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GenericResponse } from 'projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Generic/af-generic-response/af-genericResponse';
import { SupportPageDetailsViewModel } from '../BusinessEntities/support-page-details-view.model';

@Injectable({ providedIn: 'root' })
export class SupportDataService {

    baseUrl = environment.packageToolApiUrl;

    constructor(
        private dataProvider: DataService
    ) { }

    GetSupportPageDetails() {
        return this.dataProvider.Get<GenericResponse<SupportPageDetailsViewModel>>(
            this.baseUrl + 'Support/GetSupportPageDetails'
        ).toPromise();
    }

    UpdateUserResponse(response: boolean) {
        return this.dataProvider.Put<GenericResponse<boolean>>(
            this.baseUrl + 'Support/UpdateUserResponse',
            response
        ).toPromise();
    }
}
