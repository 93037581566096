// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  genericApiURL: 'https://wapbolpcore01.azurewebsites.net/api/',
  packageToolApiUrl: 'https://wapbolppt03.azurewebsites.net/api/',
  language: 'en-US',
  envName: 'PROD',
  msalConfig: {
    clientID: '15fc91d5-a009-41ac-aa97-093a949635e0',
    authority: 'https://login.microsoftonline.com/bertelosteen.onmicrosoft.com/',
    cacheLocation: 'localStorage',
    redirectUri: 'https://tilbehor.bos.no/',
    postLogoutRedirectUri: 'https://tilbehor.bos.no/',
    validateAuthority: true,
    navigateToLoginRequestUrl: true,
    popUp: false,
    consentScopes: ['user.read', 'openid', 'profile', 'api://15fc91d5-a009-41ac-aa97-093a949635e0'],
    resourceScope: "api://15fc91d5-a009-41ac-aa97-093a949635e0/user_impersonation",
    protectedResourceScopes: ['user.read']
  },
  cKey: '',
  ModId: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
