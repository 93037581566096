import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class EncryptionService {
  encryptText(plainText: string) {
    const key = CryptoJS.enc.Utf8.parse(environment.cKey.trim());
    const iv = CryptoJS.enc.Utf8.parse(
      environment.cKey.substring(0, 16).trim()
    );

    return CryptoJS.AES.encrypt(plainText.trim(), key, {
      iv,
      mode: CryptoJS.mode.CBC
    }).toString();
  }

  encryptURLParameters(plainText: string) {
    return encodeURIComponent(this.encryptText(plainText)).replace(/%/g, "!");
  }
  decrypt<T>(cypherText: string): T {
    let crypttext = cypherText.replace(/!/g, "%");
    const key = CryptoJS.enc.Utf8.parse(environment.cKey.trim());
    const iv = CryptoJS.enc.Utf8.parse(
      environment.cKey.substring(0, 16).trim()
    );
    let plaintextArray = CryptoJS.AES.decrypt(
      crypttext.trim(),
      key,
      {
        iv,
        mode: CryptoJS.mode.CBC
      }
    );
    let decrypted = CryptoJS.enc.Latin1.stringify(plaintextArray)
    return JSON.parse(decrypted);
  }
}
