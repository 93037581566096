import { Injectable } from "@angular/core";
import { PackageDetailsDataService } from "../DataServices/package-details-data.service";

@Injectable({
  providedIn: 'root',
})
export class PackageDetailsService {
  selectedModelFamily:string;
  constructor(private packageDetailsDataService:PackageDetailsDataService){

  }
 async  GetPackageDataById(id:number){
    return await  this.packageDetailsDataService.GetPackageDataById(id);
  }
}
