export class CartTotalCountViewModel{
  totalCount:number = 0;
  totalPrice:number = 0;
  vatPercent:number = 0;
}
export class ExportToDarsParamModel{
  vin:string;
  serialNo:string;
  licencePlate:string;
  dealerNo:string;
  isCarCheck:boolean;
}
export class PackageIdList{
  id : number;
  modelBrandDescription : string;
  modelFamily : string;
}