export const RouteConstants = {
    PackagePortal: 'PCLCV',
    PackagePortalTruck: 'TRUCK',
    PackagePortalExternal: 'EXT/PCLCV',
    PackageDashboard: 'package-dashboard',
    PackageDetails: `package-details/:id`,
    PackageDetailsRoute: `package-details`,
    CartDetails: 'cart-details/:cartId',
    CartDetailsDefault: 'cart-details',
    Support: 'support',
    Landing: ':brandCode',
    LandingDefault: 'PCLCV/NONE',
    LandingTruck: 'TRUCK',
    LandingTruckExt: 'EXT/TRUCK',
}

export const ChildRoutes = {
    PackageDetailsPage: `/${RouteConstants.PackagePortal}/${RouteConstants.PackageDetailsRoute}`,
    PackageDetailsTruckPage: `/${RouteConstants.PackagePortalTruck}/${RouteConstants.PackageDetailsRoute}`,
    CartPage: `/${RouteConstants.PackagePortal}/${RouteConstants.CartDetails}`,
    CartPageDefault: `/${RouteConstants.PackagePortal}/${RouteConstants.CartDetailsDefault}`,
    MainRoute:`/${RouteConstants.PackagePortal}/${RouteConstants.PackageDashboard}`,
    TruckMainRoute:`/${RouteConstants.PackagePortalTruck}/${RouteConstants.PackageDashboard}`,
    LandingRoute:`/${RouteConstants.PackagePortal}/${RouteConstants.Landing}`,
    LandingDefaultRoute:`/${RouteConstants.PackagePortal}`,
    LandingTruckRoute:`/${RouteConstants.LandingTruck}`
}

export const ChildRoutesExternal = {
    PackageDetailsPage: `/${RouteConstants.PackagePortalExternal}/${RouteConstants.PackageDetailsRoute}`,
    PackageDetailsPageForTruck: `/${RouteConstants.LandingTruckExt}/${RouteConstants.PackageDetailsRoute}`,
    CartPage: `/${RouteConstants.PackagePortalExternal}/${RouteConstants.CartDetails}`,
    CartPageDefault: `/${RouteConstants.PackagePortal}/${RouteConstants.CartDetailsDefault}`,
    MainRoute:`/${RouteConstants.PackagePortalExternal}/${RouteConstants.PackageDashboard}`,
    TruckMainRoute:`/${RouteConstants.LandingTruckExt}/${RouteConstants.PackageDashboard}`,
    LandingRoute:`/${RouteConstants.PackagePortalExternal}/${RouteConstants.Landing}`,
    LandingDefaultRoute:`/${RouteConstants.PackagePortalExternal}`,
    LandingTruckRoute:`/${RouteConstants.LandingTruckExt}`
}
