import { Injectable } from '@angular/core';
import { PackageGroupListRequestModel } from '../BusinessEntities/group-list-request-model';
import { PackageViewRecord } from '../BusinessEntities/package-view-record';
import { PackagePortalDashboardDataService } from '../DataServices/package-portal-dashboard.service';
import { PackagePortalGridParamatersViewModel } from '../BusinessEntities/package-portal-grid-paramaters-view-model';

@Injectable({ providedIn: 'root' })
export class PackagePortalDashboardService {

  constructor(
    private dataService: PackagePortalDashboardDataService
  ) { }


  async GetPackagePortalDashboardData(dealerId: string) {
    return this.dataService.GetPackagePortalDashboardData(dealerId);
  }
  async GetGroupList(packgeGroupModel: PackageGroupListRequestModel) {
    return this.dataService.GetGroupList(packgeGroupModel);
  }
  async GetPackages(packagePortalGridParamatersViewModel: PackagePortalGridParamatersViewModel) {
    return this.dataService.GetPackages(packagePortalGridParamatersViewModel);
  }
  AddVisitRecord(packageViewRecord: PackageViewRecord) {
    return this.dataService.AddVisitRecord(packageViewRecord);
  }
  async GetDefaultTag() {
    return this.dataService.GetDefaultTag();
  }
  GetTyreTypeDataForPackagePortal() {
    return this.dataService.GetTyreTypeDataForPackagePortal();
  }
  async GetChassisTypes(modelFamily: string) {
    return await this.dataService.GetChassisTypes(modelFamily);
  }

  async GetVehicleCode(globalSearchString: string) {
    return await this.dataService.GetVehicleCode(globalSearchString);
  }
  
  async GetTruckBrands() {
    return await this.dataService.GetTruckBrands();
  }
}
