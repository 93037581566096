import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'af-loader',
  templateUrl: './af-loader.component.html',
  styleUrls: ['./af-loader.component.css']
})
export class AfLoaderComponent {
  constructor(public loaderModal: NgbActiveModal) { }

  closeModal() {
    this.loaderModal.close();
  }

}

