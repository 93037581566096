import { BaseService } from './../../Autofacets.Infrastructure.Services/base-service/base.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDetails } from '../../Autofacets.Infrastructure.Common/Model/UserDetails';
import { MsalService } from '@azure/msal-angular';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivateChild, CanActivate {

  constructor(
    private baseService: BaseService,
    private msalService: MsalService) { }

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const url = route.url;
    const lang = route.parent.url[0].path;
    if (!this.baseService.userDetails) {
      return this.baseService.getUserMenudetails().then(
        (response: UserDetails) => {
          this.baseService.userDetails = response;
          if (this.baseService.isMenuAccessible(url)) {
            return true;
          } else {
            return this.baseService.routeUnAuthorizedAccess(lang);
          }
        });
    }
    else if (this.baseService.isMenuAccessible(url)) {
      return true;
    }
    else {
      return this.baseService.routeUnAuthorizedAccess(lang);
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const url = childRoute.url;
    const lang = childRoute.parent.url[0].path;
    if (!this.baseService.userDetails) {
      return this.baseService.getUserMenudetails().then(
        (response: UserDetails) => {
          this.baseService.userDetails = response;
          if (this.baseService.isMenuAccessible(url)) {
            return true;

          } else {
            return this.baseService.routeOfUnAuthorizedAccess(lang);
          }
        });
    }
    else if (this.baseService.isMenuAccessible(url)) {
      return true;
    }
    else {
      return this.baseService.routeUnAuthorizedAccess(lang);
    }
  }
}
