import { Injectable, Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'afNumberPipe'
})
@Injectable({
  providedIn: 'root'
})

// export class Constants {
//   static readonly DATE_FMT = 'dd.MM.yyyy';
//   static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} HH:mm:ss`;
// }

export class AfNumberPipe  implements PipeTransform {

  transform(value: string, args: string[]): string {
  let newValue= value ?  value.replace(/,/g, ' ') : value;
    newValue =  newValue.toString().replace(".",",");
    return newValue;
  }

}

