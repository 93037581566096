import { Directive, Input, HostListener } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';

declare let $: any;
@Directive({
  selector: '[appCopyText]',
})
export class AfTextCopyIconDirective {

  @Input() text: string;
  @Input() icon: string;

  @HostListener('click', ['$event'])
  onClick(event) {
    if(this.text != null || this.text != undefined){
      this.clipboardService.copyFromContent(this.text);
      if($(event.srcElement).find("span").length <= 0){
          setTimeout( _ => {
            event.srcElement.className = this.icon;
          }, 1000);
            if(this.icon=="ico-16 copy-white-icon"){
              event.srcElement.className = "ico-16 copy-success-icon-tooltip";
            }else if(this.icon=="ico-16 copy-icon") {
              event.srcElement.className = "ico-16 copy-success-icon-blue";
            }else{
              event.srcElement.className = "mr-1 ico-16 copy-success-icon-button";
            }
      }else{
        $(event.srcElement).find("span").removeClass("mdi-content-save left");
        $(event.srcElement).find("span").addClass("mr-1 ico-16 copy-success-icon-button");
        setTimeout(_ =>{
          $(event.srcElement).find("span").removeClass("mr-1 ico-16 copy-success-icon-button");
          $(event.srcElement).find("span").addClass("mdi-content-save left");
        },1000);
      }
    }
  }


  constructor(private readonly clipboardService: ClipboardService) {}
}

