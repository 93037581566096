import { Injectable } from '@angular/core';
import { UserDetails } from 'projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Common/Model/UserDetails';
import { Subject } from 'rxjs';
import {PackageCartRequestViewModel} from "../../projects/Shared/BusinessEntities/package-cart-view-model";
import {CartTotalCountViewModel} from "../../projects/Shared/BusinessEntities/cart-total-count-view-model";
import { ChildRoutes, ChildRoutesExternal } from 'projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Common/Constant/route.constant';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public cartTotalCountAndPrice: CartTotalCountViewModel = new CartTotalCountViewModel;
  public loggedInUsername: string;
  public userDetails: UserDetails;
  public loggedIn: boolean = false;
  public isExternalUser: boolean = false;
  public isCartFull: boolean = false;
  public cartPackagesList: PackageCartRequestViewModel[] = [];
  logoutEvent = new Subject<boolean>();
  public isTruckUser: boolean = false;
  public accessToken: string="";
  public isSetOAuthToken: boolean = false;

  constructor(private router: Router,){
  }

  redirectUrlBasedOnBrand(){
    if(this.isTruckUser) {
      this.router.navigate([ChildRoutes.TruckMainRoute]);
    } else{
      this.router.navigate([ChildRoutes.MainRoute]);
    }
  }

  redirectUrlBasedOnBrandForExternal(){
    if(this.isTruckUser) {
      this.router.navigate([ChildRoutesExternal.TruckMainRoute]);
    } else{
      this.router.navigate([ChildRoutesExternal.MainRoute]);
    }
  }


  redirectPackageDetailTruck(encryptedPackageId: string){
    if(this.isTruckUser) {
      this.router.navigate([ChildRoutes.PackageDetailsTruckPage, encryptedPackageId], { state: { example: true } });
    } else{
      this.router.navigate([ChildRoutes.PackageDetailsPage, encryptedPackageId], { state: { example: true } });
    }
  }

  redirectPackageDetailTruckforExternal(encryptedPackageId: string){
    if(this.isTruckUser) {
      this.router.navigate([ChildRoutesExternal.PackageDetailsPageForTruck, encryptedPackageId], { state: { example: true } });
    } else{
      this.router.navigate([ChildRoutesExternal.PackageDetailsPage, encryptedPackageId], { state: { example: true } });
    }
  }
}
