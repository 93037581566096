import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AfLoaderComponent } from '../Autofacets.Infrastructure.Components/af-loader-component/af-loader.component';
import { AfTextCopyIconDirective } from '../Autofacets.Infrastructure.Directives/af-textCopyIcon.directive';
import { AfNumberPipe } from '../Autofacets.Infrastructure.Pipes/custom-date-pipe/af-number.pipe';
import { AuthGuardService } from '../Autofacets.Infrastructure.Security/Auth-Guard/auth-guard.service';
import { AuthenticationService } from '../Autofacets.Infrastructure.Services/Authentication/authentication.service';
import { BaseService } from '../Autofacets.Infrastructure.Services/base-service/base.service';
import { DataService } from '../Autofacets.Infrastructure.Services/data-service/data.service';
import { AppComponent } from './app.component';
import { UserService } from '../Autofacets.Infrastructure.Services/user-service/user.service';
import { UserDataService } from '../Autofacets.Infrastructure.Services/user-service/user-data.service';
import { CartDetailsDataService } from 'projects/Shared/DataServices/cart-details-data.service';
import { CartDetailsService } from 'projects/Shared/BusinessServices/cart-details.service';
import { SupportService } from 'projects/Shared/BusinessServices/support.service';
import { SupportDataService } from 'projects/Shared/DataServices/support-data.service';
import { PackageDetailsService } from 'projects/Shared/BusinessServices/package-details.service';
import { PackageDetailsDataService } from 'projects/Shared/DataServices/package-details-data.service';
import { PackagePortalDashboardService } from 'projects/Shared/BusinessServices/package-portal-dashboard.service';
import { PackagePortalDashboardDataService } from 'projects/Shared/DataServices/package-portal-dashboard.service';

@NgModule({
  declarations: [
    AppComponent,
    AfLoaderComponent,
    AfNumberPipe,
    AfTextCopyIconDirective

  ],
  imports: [
    CommonModule,
    HttpClientModule,
    NgbModalModule,
    FormsModule,
    TranslateModule.forRoot()
  ],
  providers: [
    DatePipe
  ],
  exports: [AfLoaderComponent, AfNumberPipe, AfTextCopyIconDirective],
  bootstrap: [AppComponent]
})
export class AppModule { }

NgModule({
  exports: [
    // ValidateFieldDirective
  ]
})
export class AutofacetsInfrastructureModule {
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers: [
        AuthenticationService,
        AuthGuardService,
        BaseService,
        DataService,
        UserService,
        UserDataService,
        CartDetailsService,
        CartDetailsDataService,
        SupportService,
        SupportDataService,
        PackageDetailsService,
        PackageDetailsDataService,
        PackagePortalDashboardService,
        PackagePortalDashboardDataService
      ]
    };
  }
}
