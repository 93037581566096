<nav class="navbar navbar-expand-sm bg-dark-blue">
  <div class="container">
    <a class="navbar-brand" [routerLink]="'PCLCV'" *ngIf="!sharedService.isExternalUser && !sharedService.isTruckUser">
      <img src="/assets/images/portalen_logo.png" alt="logo" class="main-icon" />
    </a>
    <a class="navbar-brand" [routerLink]="'EXT/PCLCV'" *ngIf="sharedService.isExternalUser && !sharedService.isTruckUser">
      <img src="/assets/images/portalen_logo.png" alt="logo" class="main-icon" />
    </a>
    <a class="navbar-brand" [routerLink]="'TRUCK'" *ngIf="!sharedService.isExternalUser && sharedService.isTruckUser">
      <img src="/assets/images/portalen_logo.png" alt="logo" class="main-icon" />
    </a>
    <a class="navbar-brand" [routerLink]="'EXT/TRUCK'" *ngIf="sharedService.isExternalUser && sharedService.isTruckUser">
      <img src="/assets/images/portalen_logo.png" alt="logo" class="main-icon" />
    </a>
    <div>
      <h1 class="main-header">
        {{ brandName }}
      </h1>
    </div>
    <div class="nav-right">
      <div class="nav nav-tabs" id="nav-tab">
        <div ngbDropdown class="mx-4" *ngIf="!sharedService.isExternalUser">
          <button class="main-link nav-link active" id="dropdownBasic1" *ngIf="!sharedService.isTruckUser" [routerLink]="'PCLCV/support'"
            type="button">Støtte</button>
          <button class="main-link nav-link active" id="dropdownBasic1" *ngIf="sharedService.isTruckUser" [routerLink]="'TRUCK/support'"
            type="button">Støtte</button>
        </div>
        <div ngbDropdown class="mx-4">
          <button class="main-link nav-link active" id="dropdownBasic1" style="margin-left: 0px!important;"
            type="button" *ngIf="sharedService.loggedIn" ngbDropdownToggle>{{sharedService.loggedInUsername}}</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu dropdown-menu-right right">
            <button ngbDropdownItem (click)="logout()" class="text-center bold dropdown-item main-dropdown">Logg
              ut</button>
          </div>
        </div>
        <div [routerLink]="'PCLCV/cart-details'" style="cursor: pointer;" class="white-txt text-right mx-4" *ngIf="!sharedService.isExternalUser  && !sharedService.isTruckUser">
          <a [routerLink]="'PCLCV/cart-details'">
            <div class="product-item bold f-16 main-element">
              Din handlekurv
            </div>
            <div class="product-price f-14 semibold main-element">
              {{(sharedService.cartTotalCountAndPrice?.totalPrice > 0 ? sharedService.cartTotalCountAndPrice?.totalPrice : 0) | number:'1.0-0' |
              afNumberPipe}} kr
            </div>
          </a>
        </div>
        <div [routerLink]="'EXT/PCLCV/cart-details'" style="cursor: pointer;" class="white-txt text-right mx-4" *ngIf="sharedService.isExternalUser && !sharedService.isTruckUser">
          <a [routerLink]="'EXT/PCLCV/cart-details'">
            <div class="product-item bold f-16 main-element">
              Din handlekurv
            </div>
            <div class="product-price f-14 semibold main-element">
              {{(sharedService.cartTotalCountAndPrice?.totalPrice > 0 ? sharedService.cartTotalCountAndPrice?.totalPrice : 0) | number:'1.0-0' |
              afNumberPipe}} kr
            </div>
          </a>
        </div>
        <div [routerLink]="'TRUCK/cart-details'" style="cursor: pointer;" class="white-txt text-right mx-4" *ngIf="!sharedService.isExternalUser && sharedService.isTruckUser">
          <a [routerLink]="'TRUCK/cart-details'">
            <div class="product-item bold f-16 main-element">
              Din handlekurv
            </div>
            <div class="product-price f-14 semibold main-element">
              {{(sharedService.cartTotalCountAndPrice?.totalPrice > 0 ? sharedService.cartTotalCountAndPrice?.totalPrice : 0) | number:'1.0-0' |
              afNumberPipe}} kr
            </div>
          </a>
        </div>
        <div [routerLink]="'EXT/TRUCK/cart-details'" style="cursor: pointer;" class="white-txt text-right mx-4" *ngIf="sharedService.isExternalUser && sharedService.isTruckUser">
          <a [routerLink]="'EXT/TRUCK/cart-details'">
            <div class="product-item bold f-16 main-element">
              Din handlekurv
            </div>
            <div class="product-price f-14 semibold main-element">
              {{(sharedService.cartTotalCountAndPrice?.totalPrice > 0 ? sharedService.cartTotalCountAndPrice?.totalPrice : 0) | number:'1.0-0' |
              afNumberPipe}} kr
            </div>
          </a>
        </div>
        <span style="position: relative;">
          <a [routerLink]="'PCLCV/cart-details'" *ngIf="!sharedService.isExternalUser  && !sharedService.isTruckUser">
            <button class="nav-link cart-button" id="oversikt-tab" [routerLink]="'PCLCV/cart-details'"
              data-bs-toggle="tab" data-bs-target="#oversikt" type="button" role="tab" aria-controls="oversikt"
              aria-selected="false">
              <img src="/assets/images/cart-icon.svg" alt="cart" /></button>
            <span
              class="cart-counter d-flex align-items-center justify-content-center  ">{{sharedService.cartTotalCountAndPrice?.totalCount?sharedService.cartTotalCountAndPrice?.totalCount:0}}</span>
          </a>
          <a [routerLink]="'EXT/PCLCV/cart-details'" *ngIf="sharedService.isExternalUser && !sharedService.isTruckUser">
            <button class="nav-link cart-button" id="oversikt-tab" [routerLink]="'EXT/PCLCV/cart-details'"
              data-bs-toggle="tab" data-bs-target="#oversikt" type="button" role="tab" aria-controls="oversikt"
              aria-selected="false">
              <img src="/assets/images/cart-icon.svg" alt="cart" /></button>
            <span
              class="cart-counter d-flex align-items-center justify-content-center  ">{{sharedService.cartTotalCountAndPrice?.totalCount?sharedService.cartTotalCountAndPrice?.totalCount:0}}</span>
          </a>
          <a [routerLink]="'TRUCK/cart-details'" *ngIf="!sharedService.isExternalUser && sharedService.isTruckUser">
            <button class="nav-link cart-button" id="oversikt-tab" [routerLink]="'TRUCK/cart-details'"
              data-bs-toggle="tab" data-bs-target="#oversikt" type="button" role="tab" aria-controls="oversikt"
              aria-selected="false">
              <img src="/assets/images/cart-icon.svg" alt="cart" /></button>
            <span
              class="cart-counter d-flex align-items-center justify-content-center  ">{{sharedService.cartTotalCountAndPrice?.totalCount?sharedService.cartTotalCountAndPrice?.totalCount:0}}</span>
          </a>
          <a [routerLink]="'EXT/TRUCK/cart-details'" *ngIf="sharedService.isExternalUser && sharedService.isTruckUser">
            <button class="nav-link cart-button" id="oversikt-tab" [routerLink]="'TRUCK/cart-details'"
              data-bs-toggle="tab" data-bs-target="#oversikt" type="button" role="tab" aria-controls="oversikt"
              aria-selected="false">
              <img src="/assets/images/cart-icon.svg" alt="cart" /></button>
            <span
              class="cart-counter d-flex align-items-center justify-content-center  ">{{sharedService.cartTotalCountAndPrice?.totalCount?sharedService.cartTotalCountAndPrice?.totalCount:0}}</span>
          </a>
        </span>
      </div>
    </div>
  </div>
</nav>
<ng-template [ngIf]="sharedService.isSetOAuthToken || !isIframe ">
  <router-outlet ></router-outlet>
</ng-template>

