import { Injectable } from '@angular/core';
import { SupportDataService } from '../DataServices/support-data.service';

@Injectable({ providedIn: 'root' })
export class SupportService {
    constructor(
        private dataService: SupportDataService
    ) { }

    GetSupportPageDetails() {
        return this.dataService.GetSupportPageDetails();
    }

    UpdateUserResponse(response: boolean) {
        return this.dataService.UpdateUserResponse(response);
    }

}
