export enum GroupRoleType {

  /**
   * The unknown user
   */
  UnKnownUser = 0,

  /**
   * The admin
   */
  Admin = 1,

  /**
   * The dealer
   */
  Dealer = 2,

  /**
   * The call center
   */
  CallCenter = 3,

  /**
   * The importer
   */
  Importer = 4,

  /**
   * The external call center
   */
  ExternalCallCenter = 5,

  /**
   * The BOS owned
   */
  BOSOwned = 8,

  /**
   * The mechanic
   */
  Mechanic = 9,

  /**
   * The customer advisor
   */
  CustomerAdvisor = 10,

  /**
   * The part manager
   */
  PartManager = 11,

  /**
   * The external company user
   */
  ExternalCompanyUser = 12,

  /**
   * The invoice receiver
   */
  InvoiceReceiver = 13,
  ExtKLAR = 14
}

export enum BosMenuName {
  /**
   * The alert configuration
   */
  AlertConfiguration,

  /**
   * The appointment
   */
  Appointment,

  /**
   * The appointment warning report
   */
  AppointmentWarningReport,

  /**
   * The body repair category
   */
  BodyRepairCategory,

  /**
   * The body repair damage type
   */
  BodyRepairDamageType,

  /**
   * The body repair insurance company
   */
  BodyRepairInsuranceCompany,

  /**
   * The body repair request
   */
  BodyRepairRequest,

  /**
   * The brand
   */
  Brand,

  /**
   * The campaign admin
   */
  CampaignAdmin,

  /**
   * The campaign cause
   */
  CampaignCause,

  /**
   * The campaign cause report
   */
  CampaignCauseReport,

  /**
   * The campaign customer
   */
  CampaignCustomer,

  /**
   * The campaign external call center report
   */
  CampaignExternalCallCenterReport,

  /**
   * The campaign status
   */
  CampaignStatus,

  /**
   * The campaign summary report
   */
  CampaignSummaryReport,

  /**
   * The car body type
   */
  CarBodyType,

  /**
   * The CDSS agent details report
   */
  CdssAgentDetailsReport,

  /**
   * The CDSS agent call detail report
   */
  CdssAgentCallDetailsReport,

  /**
   * The color
   */
  Color,

  /**
   * The competence
   */
  Competence,

  /**
   * The connect offer
   */
  ConnectOffer,

  /**
   * The customer receptionist
   */
  CustomerReceptionist,

  /**
   * The dashboard
   */
  Dashboard,

  /**
   * The dealer
   */
  Dealer,

  /**
   * The dealer appointment report
   */
  DealerAppointmentReport,

  /**
   * The dealer utilization report
   */
  DealerUtilizationReport,

  /**
   * The demo car
   */
  DemoCar,

  /**
   * The demo car appointment report
   */
  DemoCarAppointmentReport,

  /**
   * The demo car category
   */
  DemoCarCategory,

  /**
   * The demo car color
   */
  DemoCarColor,

  /**
   * The demo car employee report
   */
  DemoCarEmployeeReport,

  /**
   * The demo car hq dashboard
   */
  DemoCarHqDashboard,

  /**
   * The demo car model
   */
  DemoCarModel,

  /**
   * The democar system configuration
   */
  DemocarSystemConfig,

  /**
   * The device
   */
  Device,

  /**
   * The device import
   */
  DeviceImport,

  /**
   * The distribute device
   */
  DistributeDevice,

  /**
   * The DMS account
   */
  DmsAccount,

  /**
   * The driver
   */
  Driver,

  /**
   * The DTC code
   */
  DtcCode,

  /**
   * The facility
   */
  Facility,

  /**
   * The fuel type
   */
  FuelType,

  /**
   * The holiday
   */
  Holiday,

  /**
   * The dealer holiday
   */
  DealerHoliday,

  /**
   * The ifs customer
   */
  IfsCustomer,

  /**
   * The ifs vehicle
   */
  IfsVehicle,

  /**
   * The lend to dealer report
   */
  LendToDealerReport,

  /**
   * The mechanic
   */
  Mechanic,

  /**
   * The mechanics information report
   */
  MechanicsInfoReport,

  /**
   * The model
   */
  Model,

  /**
   * My dealer
   */
  MyDealer,

  /**
   * The notification settings
   */
  NotificationSettings,

  /**
   * The patch note
   */
  PatchNote,

  /**
   * The PDF scapper accounts
   */
  PdfScapperAccounts,

  /**
   * The planner
   */
  Planner,

  /**
   * The press group appointment report
   */
  PressGroupAppointmentReport,

  /**
   * The press group customer
   */
  PressGroupCustomer,

  /**
   * The press group planner report
   */
  PressGroupPlannerReport,

  /**
   * The rental car company booking
   */
  RentalCarCompanyBooking,

  /**
   * The rental car company category
   */
  RentalCarCompanyCategory,

  /**
   * The rental car company category price
   */
  RentalCarCompanyCategoryPrice,

  /**
   * The rental car company contact
   */
  RentalCarCompanyContact,

  /**
   * The rental car company
   */
  RentalCarCompany,

  /**
   * The reserve car
   */
  ReserveCar,

  /**
   * The reserve car email configuration
   */
  ReserveCarEmailConfiguration,

  /**
   * The road side assistance
   */
  RoadSideAssistance,

  /**
   * The robot process log
   */
  RobotProcessLog,

  /**
   * The roles
   */
  Roles,

  /**
   * The schedule
   */
  Schedule,

  /**
   * The service
   */
  Service,

  /**
   * The service data report
   */
  ServiceDataReport,

  /**
   * The SMS templates
   */
  SmsTemplates,

  /**
   * The sos category
   */
  SosCategory,

  /**
   * The system configuration
   */
  SystemConfig,

  /**
   * The test drive appointment
   */
  TestDriveAppointment,

  /**
   * The test drive contact form
   */
  TestDriveContactForm,

  /**
   * The test drive offer
   */
  TestDriveOffer,

  /**
   * The tracker user log report
   */
  TrackerUserLogReport,

  /**
   * The user role
   */
  UserRole,

  /**
   * The vehicle
   */
  Vehicle,

  /**
   * The zisson dealer
   */
  ZissonDealer,

  /**
   * The dealer service
   */
  DealerService,

  /**
   * The SPPS part mapping
   */
  SppsPartMapping,

  /**
   * The price engine admin
   */
  PriceEngineAdmin,

  /**
   * The competence linked to operation
   */
  CompetenceLinkedToOperation,

  /**
   * The user manual
   */
  UserManual,

  /**
   * the demo car user manual
   */
  DemoCarUserManual,

  /**
   * The campaign user manual
   */
  CampaignUserManual,

  /**
   * The minbil user manual
   */
  MinbilUserManual,

  /**
   * The PDF scrapper user manual
   */
  PdfScrapperUserManual,

  /**
   * The reserve car user manual
   */
  ReserveCarUserManual,

  /**
   * The price cause
   */
  PriceCause,

  /**
   * The user configuration
   */
  UserConfiguration,
  /**
   * The comm user filter configuration
   */
  CommUserFilterConfiguration,
  /**
   * Car Pool
   */
  DemoCarPool,

  /**
   * The price cause report
   */
  PriceCauseReport,

  /**
   * New Letter Signup Report
   */
  NewsletterSignupReport,

  /**
   * The check list management
   */
  CheckListManagement,

  /**
   * The diagnose check list
   */
  DiagnoseCheckList,

  /**
   * The diagnose templates
   */
  DiagnoseTemplates,

  /**
   * The settings
   */
  Settings,

  /**
   * The technical manual
   */
  TechnicalManual,

  /**
   * The upload parking picture
   */
  UploadParkingPicture,

  /**
   * The demo car dealer configuration
   */
  DemoCarDealerConfiguration,

  /**
   * The template
   */
  Template,

  /**
   * The notification configuration
   */
  NotificationConfiguration,

  /**
   * The tyre hotel booking cars report
   */
  TyreHotelBookingCarsReport,

  /**
   * The campaign manager work order report
   */
  CampaignManagerWorkOrderReport,

  /**
   * The dealer information
   */
  DealerInformation,

  /**
   * The dealer prices
   */
  DealerPrices,

  /**
   * The dealer contact information
   */
  DealerContactInfo,

  /**
   * The sundry mapping
   */
  SundryMapping,

  /**
   * The dealer todays message
   */
  DealerTodaysMessage,

  /**
   * The dealer facilities
   */
  DealerFacilities,

  /**
   * The dealer attention message
   */
  DealerAttentionMessage,

  /**
   * The SSM account
   */
  SsmAccount,

  /**
   * The CDSS
   */
  CDSS,

  /**
   * The block notification
   */
  BlockCustomerNotification,

  /**
   * The customer order tracker
   */
  CustomerOrderTracker,

  /**
   * The key drop location
   */
  KeyDropLocation,

  /**
   * The part issues
   */
  PartIssues,

  /**
   * The dashboard to do list
   */
  DashboardToDoList,

  /**
   * The dealer part price
   */
  DealerPartPrice,

  /**
   * The workshop messages
   */
  WorkshopMessage,

  /**
   * The communication role
   */
  CommSectionRoles,

  /**
   * The key position
   */
  KeyPosition,

  /**
   * The dealer key position
   */
  DealerKeyPosition,

  /**
   * The reserve car refund
   */
  ReserveCarRefund,

  /**
   * The event trigger
   */
  EventTrigger,

  /**
   * The parts order
   */
  PartsOrder,

  /**
   * The parts sales customer
   */
  PartsSalesCustomer,

  /**
   * The CDSS region
   */
  CdssRegion,

  /**
   * The CDSS dealer region
   */
  CdssDealerRelation,

  /**
   * The warranty claim
   */
  WarrantyClaim,

  /**
   * The notification configuration flag
   */
  NotificationConfigurationFlag,

  /**
   * The service category
   */
  ServiceCategory,

  /**
   * The warning light
   */
  WarningLight,

  /**
   * The price example report
   */
  PriceExampleReport,

  /**
   * The price example
   */
  PriceExample,

  /**
   * The external rental car booking
   */
  ExternalRentalCarBooking,


  /**
   * The external demo car
   */
  ExternalDemoCar,

  /**
   * The external rental car dashboard
   */
  ExternalRentalCarDashboard,

  /**
   * The special tool
   */
  SpecialTool,
  // ExternalRentalCarDashboard,

  /**
   * The dealer special tool
   */
  DealerSpecialTool,

  /**
   * To do dashboard section
   */
  ToDoDashboardSection,

  /**
   * The mechanic service report
   */
  MechanicServiceReport,

  /**
   * The external company customer
   */
  ExternalCompanyCustomer,

  /**
   * The external company wo history
   */
  ExternalCompanyWoHistory,

  /**
   * The damage row type
   */
  DamageRowType,

  /**
   * The damage workshop department
   */
  DamageWorkshopDepartment,

  /**
   * The mechanic time code
   */
  MechanicTimeCode,

  /**
   * The insurance company
   */
  InsuranceCompany,

  /**
   * The body repair
   */
  BodyRepair,

  /**
   * The dealer DMS account
   */
  DealerDmsAccount,

  /**
   * The Alternate OE Part
   */
  AlternateOePart,

  /**
   * The part order priorities
   */
  PartOrderPriorities,

  /**
   * The compilator
   */
  DealerMechanicCompilator,

  /**
   * The Stamp In/Out
   */
  StampInOut,

  /**
   * The automatic key
   */
  AutoKey,

  /**
   * PartsSorting Menu
   */
  PartsSorting,

  /**
   * The action type monitor
   */
  ActionTypeMonitor,

  RackManagement
}

export enum RoleModule {
  /**
   * The olp module
   */
  OlpModule = 1,

  /**
   * The demo car
   */
  DemoCar = 2,

  /**
   * The campaign
   */
  Campaign = 3,

  /**
   * The bos connect
   */
  BosConnect = 4,

  /**
   * The PDF scrapper
   */
  PdfScrapper = 5,

  /**
   * The reserve car
   */
  ReserveCar = 6,

  /**
   * The dwo
   */
  Dwo = 7,

  /**
   * The communication
   */
  Communication = 8,

  /**
   * PartsSorting Module
   */
  PartsSorting = 9,
  DARSAdmin = 10,
  DARSCDSS = 11,
  DARS = 12,
  DARSImporter = 13,
  DARSRoleRight = 14,
  ServiceContract = 15
}

export enum ResponseCode {
  Success = 200,
  UnAuthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InterrnalError = 500,
  ValidationFailure = 1
}


export enum PageType {
  Add = "Add",
  Edit = "Edit",
  View = "View",
  Delete = "Delete"

}

export enum ErrorType {
  Unauthorized = "Unauthorized",
  Unknownuser = "Unknownuser",
  Unknownerror = "Unknownerror"
}

export enum Culture {
  English = 'en-US',
  Norwegian = 'nb-NO'
}

export enum CallReportFieldType {
  IncomingCalls = "1",
  Answered = "2",
  Lost = "3",
  AnsweredPer = "4",
  LostPer = "5",
  AnsweredWithin60Sec = "6",
  AnsweredWithin30Sec = "7",
  AnsweredWithin60SecPer = "8",
  AnsweredWithin30SecPer = "9",
  AvgAnsweredCallQueueTime = "10",
  AvgLostCallQueueTime = "11"
}
export enum AppointmentWorkType {
  NOS = '1',
  DELER = '2',
  KLAR = '3'
}

export enum Source {
  OLP = '1',
  IFS = '2',
  Widget = '3',
  Connect = '4',
  Save = '5',
  Peugeot = '6',
  Mercedes = '7',
  PdfScrapper = '8',
  Citroen = '9',
  Ds = '10',
  Incadea = '11',
  Dwo = '12',
  DemoCar = '13',
  Kiosk = '14',
  Dbs = '15',
  Overflow = '16',
  Kia = '17',
  PartsSorting = '18',
  VH = '19',
  Rental = '20',
  DSD = '21',
  DARS = '22',
  Next = '23'
}
export enum PriceCauseType {
  ChangeFixedPriceCause = 1,
  DeleteDemandCause = 2,
  DeleteJobCause = 3,
  DeleteJobDetailCause = 4,
  DeleteAppointmentCause = 5,
  ChangeDemandFixedPriceCause = 6
}

export enum DemoCarAppointmentType {
  TestDrive = 1,
  CarMaintenance = 2,
  Internal = 3,
  Workshop = 4,
  PressGroup = 5,
  LendToDealer = 6,
  BodyShop = 7,
  Rental = 8
}
export enum OlpWorkType {
  Appointment = 1,
  PartsOrder = 2,
  BodyRepair = 3,
  KLAR = 4
}

export enum CostType {
  Material = 'Material',
  Personnel = 'Personnel',
  External = 'External'
}
export enum CauseCategoryEnum {
  Delete = '1',
  PriceChange = '2',
  WorkOrderCancel = '3'
}
export enum SalesPart {
  RentalCarDefaultSalesPart = 'LBL0',
  RentalCarExternalSalesPart = 'LBLT',
  RentalCarExternalTollRoad = 'LBLTUM'
}

export enum XentryPackageType {
  ServiceMeasureCollection = '1',

  AdditionalOperation = '2',

  Complaint = '3',

  Maintenance = '4',

  Request = '5',

  Unassigned = '6'
}

export enum WorkOrderStatus {
  WorkRequest = '1',
  Prepared = '2',
  Started = '3',
  WorkDone = '4',
  Reported = '5',
  Finished = '6',
  Released = '7',
  Cancelled = '8',
  MechanicDone = '9'
}

export enum ChangePartStatus {
  /// <summary>
  /// The working by spare part dept
  /// </summary>
  WorkingBySparePartDept = 1,

  /// <summary>
  /// To be cancelled
  /// </summary>
  ToBeCancelled = 2,

  /// <summary>
  /// To warehouse
  /// </summary>
  ToWarehouse = 3,

  /// <summary>
  /// The purchased from factory
  /// </summary>
  PurchasedFromFactory = 4,

  /// <summary>
  /// The warehouse fjeldbo
  /// </summary>
  WarehouseFjeldbo = 5,

  /// <summary>
  /// The spare part ok
  /// </summary>
  SparePartOk = 6,

  /// <summary>
  /// The awaiting picking
  /// </summary>
  AwaitingPicking = 7,

  /// <summary>
  /// To garage
  /// </summary>
  ToGarage = 8,

  /// <summary>
  /// The remove status
  /// </summary>
  RemoveStatus = 9,

  /// <summary>
  /// For stock
  /// </summary>
  ForStock = 11,

  /// <summary>
  /// The sharing ok
  /// </summary>
  SharingOk = 12,

  /// <summary>
  /// To the work shop
  /// </summary>
  ToTheWorkShop = 13,

  /// <summary>
  /// The ordered factory
  /// </summary>
  OrderedFactory = 14,

  /// <summary>
  /// The waiting for picking
  /// </summary>
  WaitingForPicking = 15,

  /// <summary>
  /// The direct order
  /// </summary>
  DirectOrder = 16
}

export enum TemplateType {
  /// <summary>
  /// The all
  /// </summary>
  All = 0,

  /// <summary>
  /// The email
  /// </summary>
  Email = 1,

  /// <summary>
  /// The SMS
  /// </summary>
  Sms = 2,

  /// <summary>
  /// The push notification
  /// </summary>
  PushNotification = 3,

  /// <summary>
  /// The notification
  /// </summary>
  Notification = 4
}
export enum GroupRoleTypes {
  UnKnownUser = 0,
  Admin = 1,
  Dealer = 2,
  CallCenter = 3,
  Importer = 4,
  ExternalCallCenter = 5,
  BOSOwned = 8,
  Mechanic = 9,
  CustomerAdvisor = 10,
  PartManager = 11,
  ExternalCompanyUser = 12,
  InvoiceReceiver = 13,
  ExtKLAR = 14
}

export enum SupplyCodes {
  InventOrder = 'INVENT_ORDER',
  PurchaseOrder = 'PURCHASE_ORDER'
}

export enum ReleaseStatus {
  DO = 1,
  SO = 2
}

export enum DemoCarAppointmentStatus {
  Confirmed = 1,
  Cancelled = 2,
  Completed = 3,
  Pending = 4,
  Invoiced = 5
}

export enum AdditionalHoursPlanner {
  AdditionalHoursColor = '#FFB6C1'
  //AdditionalHoursColor = 'rgb(153,50,204)'

  // //AdditionalHoursColor = 'rgb(75,0,130)'
  // //AdditionalHoursColor = 'rgb(138,43,226)'
}

export enum WorkeDonePlanner {
  WorkDone = '3px solid green'
}

export enum WorkOrderWorkType {
  Bbgar = 'BBGAR',
  Dekkhotell = 'DEKKHOTELL',
  Deler = 'DELER',
  Demo = 'DEMO',
  Drdem = 'DRDEM',
  Gar = 'GAR',
  Klar = 'KLAR',
  Ko = 'KO',
  Kosklar = 'KOSKLAR',
  Kul = 'KUL',
  Nos = 'NOS',
  Omarb = 'OMARB',
  Pakost = 'PÅKOST',
  Pakostbrukt = 'PÅKOST BRUKT',
  Repvedl = 'REP/VEDL',
  Ser = 'SER',
  Takst = 'TAKST',
}

export enum InvoiceEdit {
  ReferenceNo = 1,
  Notes = 2
}

export enum PlanningTime {
  DeafultPlanningTime = 15
}

export enum DiagnoseCheckList {
  DiagnoseCheckList = 2,
  CheckList = 1
}

export enum DemoCarPlannerStatus {
  Scheduled = '#00b0a9',
  Delivered = '#eb5b4f',
  Submitted = '#0000FF',
  Completed = '#808080',
  DelayedDelivery = '#fab82d',
  DelayedSubmission = '',
  Invoiced = '#8d519b'
}

export enum DemoCarPlannerStatusType {
  Scheduled = 'Scheduled',
  Delivered = 'Delivered',
  Submitted = 'Submitted',
  Completed = 'Completed',
  DelayedDelivery = 'DelayedDelivery',
  DelayedSubmission = 'DelayedSubmission',
  Invoiced = 'Invoiced'
}

export enum ContractStatus {
  Draft = 1,
  Offer = 2,
  Contract = 3,
  Active = 18,
  SentForSigning = 20,
  Signed = 8,
  ForApproval = 21,
  AwaitingDelivery= 23
}

export enum PayerSelection{
  SameASCustomer = 1,
  OwnDealer = 2,
  LeasingCompany = 3,
  Other = 4
}
