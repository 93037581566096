
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

declare let $: any;
@Injectable({
  providedIn: 'root'
})
export class AfHelper {
  cartTotalCountAndPrice = new Subject<boolean>();

  public GetCartTotalCountAndPrice(): Observable<boolean> {
    return this.cartTotalCountAndPrice.asObservable();
  }
  public SetCartTotalCountAndPrice(status: boolean) {
    return this.cartTotalCountAndPrice.next(status);
  }

  public IsNotNullEmptyOrUndefined(value: any) {
    return !this.isNullOrUndefined(value) && value;
  }
  public isNullOrUndefined(value: any) {
    return (value === null || value === undefined);
  }
  public IsNullEmptyOrUndefined(value: any) {
    return this.isNullOrUndefined(value) || value === '';
  }

  public IsNumeric(event) {
    const keyCode = (event.which) ? event.which : event.keyCode;
    // Allow: backspace, delete, tab, escape, and enter (do not allow dot)
    //// Allow: backspace, delete, tab, escape, enter and .
    if ($.inArray(keyCode, [8, 9, 27, 13, 190, 44, 45, 46]) !== -1 ||
      // Allow: Ctrl+A, Command+A
      (keyCode == 65 && (event.ctrlKey === true || event.metaKey === true))) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((event.shiftKey || (keyCode < 48 || keyCode > 57))) {
      event.preventDefault();
    }
  }

  CloneObject(data) {
    return JSON.parse(JSON.stringify(data));
  }




}
