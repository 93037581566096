import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { SharedService } from 'src/app/shared.service';
import { EncryptionService } from '../../Autofacets.Infrastructure.Security/Encryption/encryption.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public accessToken: string = '';
  public isSetOAuthToken: boolean = false;
  constructor(
    private readonly encryptionService: EncryptionService,
    private http: HttpClient,
    private sharedService:SharedService
  ) { }

  private prepareOptions(
    isContentTypeEnabled: boolean = true,
    params: any = null
  ): any {
    let headers = new HttpHeaders();

    if(this.sharedService.isSetOAuthToken) {
      headers = headers
      .set('Accept', 'application/json')
      .set('Authorization', `Bearer ` + this.sharedService.accessToken);

    } else{
      headers = headers
      .set('Accept', 'application/json');
    }

    if (isContentTypeEnabled) {
      headers = headers.set('Content-Type', 'application/json');
    }
    if (params != null) {
      headers = headers.set(
        'Payload',
        this.encryptionService.encryptText(JSON.stringify(params))
      );
    }

    return { headers };
  }

  GetWithBasicAuth<T>(uri: string, ...params: any[]) {
    let headers = new HttpHeaders();
    const encryptedParamsString: string = params.map(param => encodeURIComponent(param.toString()))
      .join('/')
    return this.http.get(`${uri}/${encryptedParamsString}`, { headers }).pipe(
      catchError(err => {
        return err;
      }),
      map((response: T) => {
        return response;
      })
    );
  }

  private getToken() {
    if (window.location.href.split('/')[3] == 'EXT'){
      return (localStorage.getItem("token_external"))
    }
    else {
      return (localStorage.getItem("token"))
    }
  }

  GetAll<T>(uri: string) {
    const options = this.prepareOptions();
    return this.http.get(`${uri}`, options).pipe(
      catchError(err => {
        return err;
      }),
      map((response: T) => {
        return response;
      })
    );
  }

  GetAllwithHeaders<T>(uri: string, params: any) {
    const options = this.prepareOptions(true, params);
    return this.http.get(`${uri}`, options).pipe(
      catchError(err => {
        return err;
      }),
      map((response: T) => {
        return response;
      })
    );
  }

  Get<T>(uri: string, ...params: any[]) {
    const options = this.prepareOptions();
    const encryptedParamsString: string = params
      .map(param =>
        encodeURIComponent(this.encryptionService.encryptText(param.toString()))
      )
      .join('/')
      .replace(/%/g, '!');
    return this.http.get(`${uri}/${encryptedParamsString}`, options).pipe(
      catchError(err => {
        return err;
      }),
      map((response: T) => {
        return response;
      })
    );
  }

  GetWithoutEncryption<T>(uri: string, httpOptions?: any) {
    const options = this.prepareOptions();
    if (httpOptions?.responseType) {
      options.responseType = httpOptions.responseType;
    }
    return this.http.get(uri, options).pipe(
      catchError(err => {
        return err;
      }),
      map((response: T) => {
        return response;
      })
    );
  }

  PostWithHeaders<T>(uri: string, params: any, httpOptions?: any) {
    const options = this.prepareOptions();
    if (httpOptions?.responseType) {
      options.responseType = httpOptions.responseType;
    }
    return this.http
      .post(
        uri,
        this.encryptionService.encryptText(JSON.stringify(params)),
        options
      )
      .pipe(
        catchError(err => {
          return err;
        }),
        map((response: T) => {
          return response;
        })
      );
  }

  PostWithHeadersWithToken<T>(uri: string, params: any, httpOptions?: any) {
    let options = this.prepareOptions();
    options.headers = options.headers.set('Authorization', `Bearer ${this.getToken()}`);
    if (httpOptions?.responseType) {
      options.responseType = httpOptions.responseType;
    }
    return this.http
      .post(
        uri,
        this.encryptionService.encryptText(JSON.stringify(params)),
        options
      )
      .pipe(
        catchError(err => {
          return err;
        }),
        map((response: T) => {
          return response;
        })
      );
  }

  Post<T>(uri: string, params: any) {
    const options = this.prepareOptions();
    return this.http
      .post(
        uri,
        this.encryptionService.encryptText(JSON.stringify(params)),
        options
      )
      .pipe(
        catchError(err => {
          return err;
        }),
        map((response: T) => {
          return response;
        })
      );
  }

  PostWithoutEncryption<T>(uri: string, params: any) {
    const options = this.prepareOptions();
    return this.http
      .post(
        uri,
        JSON.stringify(params),
        options
      )
      .pipe(
        catchError(err => {
          return err;
        }),
        map((response: T) => {
          return response;
        })
      );
  }

  PostForm<T>(uri: string, params: any) {
    const options = this.prepareOptions(false);
    return this.http.post(uri, params, options).pipe(

      catchError(err => {
        return err;
      }),
      map((response: T) => {
        return response;
      })
    );
  }


  PostFormWithHeaders<T>(uri: string, params: any, httpOptions?: any) {
    let options = this.prepareOptions(false);

    if (httpOptions?.responseType) {
      options = options.headers.set('Content-Type', 'multipart/form-data');
      options.responseType = httpOptions.responseType;
    }

    return this.http.post(uri, params, options).pipe(

      catchError(err => {

        return err;
      }),
      map((response: T) => {

        return response;
      })
    );
  }

  Put<T>(uri: string, params: any) {
    const options = this.prepareOptions();
    return this.http
      .put(
        uri,
        this.encryptionService.encryptText(JSON.stringify(params)),
        options
      )
      .pipe(
        catchError(err => {
          return err;
        }),
        map((response: T) => {
          return response;
        })
      );
  }
  PutForm<T>(uri: string, params: any) {
    const options = this.prepareOptions(false);
    return this.http
      .put(
        uri,
        params,
        options
      )
      .pipe(
        catchError(err => {
          return err;
        }),
        map((response: T) => {
          return response;
        })
      );
  }
  Patch<T>(uri: string, params: any) {
    const options = this.prepareOptions();
    return this.http
      .patch(
        uri,
        this.encryptionService.encryptText(JSON.stringify(params)),
        options
      )
      .pipe(
        catchError(err => {
          return err;
        }),
        map((response: T) => {
          return response;
        })
      );
  }
  Delete<T>(uri: string, params: any) {
    const options = this.prepareOptions();
    let encryptedUrl = '';
    if (params != null) {
      encryptedUrl = encodeURIComponent(
        this.encryptionService.encryptText(params.toString())
      ).replace(/%/g, '!');
    }
    return this.http.delete(`${uri}/${encryptedUrl}`, options).pipe(
      catchError(err => {
        return err;
      }),
      map((response: T) => {
        return response;
      })
    );
  }
}
