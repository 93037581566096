import { Injectable } from "@angular/core";
import { GenericResponse } from "projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Generic/af-generic-response/af-genericResponse";
import { EncryptionService } from "projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Security/Encryption/encryption.service";
import { DataService } from "projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Services/data-service/data.service";
import { environment } from "src/environments/environment";
import { CartTotalCountViewModel, ExportToDarsParamModel, PackageIdList } from "../BusinessEntities/cart-total-count-view-model";
import { DealerViewModel } from "../BusinessEntities/dealer-view-model";
import { PackageCartRequestViewModel } from "../BusinessEntities/package-cart-view-model";
import { PartsOrderRequestModel } from "../BusinessEntities/parts-order-request-model";
import { PartsOrderUpdateViewModel } from "../BusinessEntities/parts-order-update-view-model";
import { UserCartDetailsViewModel } from "../BusinessEntities/user-cart-details-view-model";
import { PhoneNoViewModel } from "../BusinessEntities/phone-no-view-model";
import { IfsVehicleCustomer } from "../BusinessEntities/ifs-vehicle-view-model";
import { ExternalUserDetailsViewModel } from "../BusinessEntities/external-user-details-view-model";
import { SharedService } from 'src/app/shared.service';

@Injectable({
  providedIn: 'root',
})
export class CartDetailsDataService {
  baseURL = environment.packageToolApiUrl;
  controllerName="";
  constructor(private dataProvider: DataService, private encryptionService: EncryptionService, private sharedService: SharedService) {

  }

  assignControllerName(){
    if(this.sharedService.isExternalUser) {
      this.controllerName = "PackagePortalExternal";
    } else{
      this.controllerName = "PackagePortalInternal";
    }
  }
  AddUpdateCartDetails(packageCartRequestModel: PackageCartRequestViewModel) {
    return this.dataProvider.Post<GenericResponse<boolean>>(
      this.baseURL + 'PackagePortal/AddUpdateCartDetails',
      packageCartRequestModel
    ).toPromise();

  }
  CreatePartsOrder(partsOrderRequestModel: PartsOrderRequestModel) {
    return this.dataProvider.Post<any>(
      this.baseURL + 'PackageToolWorkOrder/CreateWorkOrder',
      partsOrderRequestModel
    ).toPromise();

  }
  UpdatePartsOrder(updatePartsOrderRequestModel: PartsOrderUpdateViewModel) {
    return this.dataProvider.Post<any>(
      this.baseURL + 'PackageToolWorkOrder/UpdateWorkOrder',
      updatePartsOrderRequestModel
    ).toPromise();
  }

  GetWorkOrderById(workOrderId: string) {
    return this.dataProvider.Get<any>(
      this.baseURL + 'PackageToolWorkOrder/GetWorkOrderById',
      workOrderId
    ).toPromise();
  }

  GetPackageCartDetails() {
    return this.dataProvider
      .Get<GenericResponse<UserCartDetailsViewModel>>(
        this.baseURL + 'PackagePortal/GetPackageCartDetails',
        this.sharedService.userDetails.defaultDealerId).toPromise();
  }
  GetAllSk2022Dealers() {
    this.assignControllerName();
    return this.dataProvider
      .Get<GenericResponse<Array<DealerViewModel>>>(
        this.baseURL + this.controllerName +'/GetAllSk2022Dealers').toPromise();
  }
  GetPackagesForUpdate() {
    return this.dataProvider
      .Get<GenericResponse<UserCartDetailsViewModel>>(
        this.baseURL + 'PackagePortal/GetPackagesForUpdate').toPromise();
  }
  DeletePackageFromCart(packageId: number, isWholePackageRemove: boolean, isOnlyMaterial: boolean) {
    return this.dataProvider
      .Delete<GenericResponse<boolean>>(
        this.baseURL + 'PackagePortal/DeletePackageFromCart/' +
        encodeURIComponent(
          this.encryptionService.encryptText(packageId.toString())
        ).replace(/%/g, '!') + '/' + encodeURIComponent(this.encryptionService.encryptText(isOnlyMaterial.toString())).replace(/%/g, '!'), isWholePackageRemove,).toPromise();
  }
  DeleteAllPackageFromCart() {
    return this.dataProvider
      .Get<GenericResponse<boolean>>(
        this.baseURL + 'PackagePortal/DeleteAllPackageFromCart').toPromise();
  }
  UpdateWorkOrderNumber(workOrderNumber: string) {
    return this.dataProvider
      .Post<GenericResponse<boolean>>(
        this.baseURL + 'PackagePortal/UpdateWorkOrderNumber/' + this.encryptionService.encryptURLParameters(workOrderNumber), null).toPromise();
  }
  GetCartTotalCount() {
    return this.dataProvider
      .Get<GenericResponse<CartTotalCountViewModel>>(
        this.baseURL + 'PackagePortal/GetCartTotalCount').toPromise();
  }
  ExportToDars(exportToDarsParam: ExportToDarsParamModel) {
    return this.dataProvider
      .Post<GenericResponse<Array<PackageIdList>>>(
        this.baseURL + 'PackagePortal/PackageExportCheck', exportToDarsParam).toPromise();
  }
  GetGroupById(id: number) {
    this.assignControllerName();
    return this.dataProvider.Get<GenericResponse<string>>(
      this.baseURL + this.controllerName +'/GetGroupById/' + this.encryptionService.encryptURLParameters(id.toString())).toPromise();
  }
  GetCartPackages(packageCartRequestViewModels: PackageCartRequestViewModel[]) {
    this.assignControllerName();
    return this.dataProvider.Post<UserCartDetailsViewModel>(
      this.baseURL + this.controllerName +'/GetCartPackages', packageCartRequestViewModels
    ).toPromise();
  }
  SendOTP(phoneNoViewModel: PhoneNoViewModel) {
    this.assignControllerName();
    return this.dataProvider.Post<boolean>(
      this.baseURL + this.controllerName +'/SendOTP', phoneNoViewModel
    ).toPromise();
  }
  ValidateOtpCode(mobileNo: string, otpCode: string) {
    this.assignControllerName();
    return this.dataProvider.Get<boolean>(
      this.baseURL + this.controllerName +'/ValidateOtpCode/' + this.encryptionService.encryptURLParameters(mobileNo) + '/' + this.encryptionService.encryptURLParameters(otpCode)).toPromise();
  }
  GetCustomerDetails(mobileNo: string) {
    this.assignControllerName();
    return this.dataProvider.Get<IfsVehicleCustomer>(
      this.baseURL + this.controllerName +'/GetCustomerDetails/' + this.encryptionService.encryptURLParameters(mobileNo)
    ).toPromise();
  }
  SendExternalCartEmail(externalUserDetailsViewModel: ExternalUserDetailsViewModel) {
    this.assignControllerName();
    return this.dataProvider.Post<boolean>(
      this.baseURL + this.controllerName +'/SendExternalCartEmail/', externalUserDetailsViewModel
    ).toPromise();
  }
  GetExternalCartDetails(externalCartId: number) {
    return this.dataProvider.Get<UserCartDetailsViewModel>(
      this.baseURL + 'PackagePortal/GetExternalCartDetails/' + this.encryptionService.encryptURLParameters(externalCartId.toString())
    ).toPromise();
  }
  GetPreferredDealer(searchText: string) {
    this.assignControllerName();
    return this.dataProvider.Get<string>(
      this.baseURL + this.controllerName +'/GetPreferredDealer/' + this.encryptionService.encryptURLParameters(searchText)
    ).toPromise();
  }
  AddSoldCount(packageId: number) {
    this.assignControllerName();
    return this.dataProvider.Post<boolean>(
      this.baseURL + this.controllerName +'/AddSoldCount', packageId
    ).toPromise();
  }
}
