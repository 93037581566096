import { Injectable } from "@angular/core";
import { EncryptionService } from "projects/autofacets-infrastructure/src/Autofacets.Infrastructure.Security/Encryption/encryption.service";
import { BehaviorSubject, Observable } from "rxjs";
import { PackageDetailsService } from "./package-details.service";
import { SharedService } from "src/app/shared.service";

@Injectable({
  providedIn: 'root',
})

export class CommmonDataFilterService {
  selectedBrandId: number = 0;
  selectedBrandName: string = '';
  selectedModelFamily: string = '';
  selectedGroupIds: number[] = [];
  selectedTyreType: number = -1;
  isTPMS: boolean = false;
  isIncVat: boolean = true;
  isOnlyMaterial: boolean = false;
  searchString: string = '';
  freeSearchString:string = '';
  themeAdded:boolean = false;
  themePosition:number = 0;
  vatPercent:number = 0;
  chassisType:number = -1;
  private vehicleDataBehaviour = new BehaviorSubject<any>({
    VehicleBrandId:-1,
    VehicleModelFamily:'-1'
  });

  constructor(
    private encryptionService: EncryptionService,
    private packageDetailsService: PackageDetailsService,
    private sharedService: SharedService
  ) {}

  getVehicleData(): Observable<any> {
      return this.vehicleDataBehaviour.asObservable();
  }

  setVehicleData(currentData: any) {
      this.vehicleDataBehaviour.next(currentData);
  }

  manageSessionStorage(){
    if (sessionStorage.getItem(this.encryptionService.encryptText('modelFamily')) && (this.packageDetailsService.selectedModelFamily == undefined)) {
      this.packageDetailsService.selectedModelFamily = sessionStorage.getItem(this.encryptionService.encryptText('modelFamily'))
      this.selectedModelFamily = this.packageDetailsService.selectedModelFamily;
    }

    if (sessionStorage.getItem(this.encryptionService.encryptText('groups')) && (this.selectedGroupIds.length == 0)) {
      this.selectedGroupIds = sessionStorage.getItem(this.encryptionService.encryptText('groups')).split(',').map(Number)
    }

    if (sessionStorage.getItem(this.encryptionService.encryptText('tyreType')) && (this.selectedTyreType == -1)) {
      this.selectedTyreType = Number(sessionStorage.getItem(this.encryptionService.encryptText('tyreType')))
    }

    if (sessionStorage.getItem(this.encryptionService.encryptText('isTPMS')) && (!this.isTPMS)) {
      this.isTPMS = Boolean(sessionStorage.getItem(this.encryptionService.encryptText('isTPMS')))
    }

    if (sessionStorage.getItem(this.encryptionService.encryptText('chassisType')) && (this.chassisType == -1)) {
      this.chassisType = Number(sessionStorage.getItem(this.encryptionService.encryptText('chassisType')))
    }

    if (sessionStorage.getItem(this.encryptionService.encryptText('searchString')) && (this.searchString == '')) {
      this.searchString = sessionStorage.getItem(this.encryptionService.encryptText('searchString'))
    }

    this.initialSetup();
  }

  initialSetup(){

    sessionStorage.setItem(this.encryptionService.encryptText('brandId'), this.encryptionService.encryptText((this.selectedBrandId).toString()));

    if (this.packageDetailsService.selectedModelFamily != '' && this.packageDetailsService.selectedModelFamily != undefined) {
      sessionStorage.setItem(this.encryptionService.encryptText('modelFamily'), (this.packageDetailsService.selectedModelFamily).toString());
    }

    if (this.selectedGroupIds.length > 0) {
      sessionStorage.setItem(this.encryptionService.encryptText('groups'), (this.selectedGroupIds).toString());
    }

    if (this.selectedTyreType != -1) {
      sessionStorage.setItem(this.encryptionService.encryptText('tyreType'), (this.selectedTyreType).toString());
    }

    if (this.isTPMS) {
      sessionStorage.setItem(this.encryptionService.encryptText('isTPMS'), (this.isTPMS).toString());
    }

    if (this.chassisType != -1) {
      sessionStorage.setItem(this.encryptionService.encryptText('chassisType'), (this.chassisType).toString());
    }

    if (this.searchString != '') {
      sessionStorage.setItem(this.encryptionService.encryptText('searchString'), (this.searchString).toString());
    }
  }

  manageTruckUser() {
    if (sessionStorage.getItem(this.encryptionService.encryptText('isTruckUser'))) {
      if ((sessionStorage.getItem(this.encryptionService.encryptText('isTruckUser'))) == 'true'){
        this.sharedService.isTruckUser = true;
      }
      else {
        this.sharedService.isTruckUser = false;
      }
    }

    sessionStorage.setItem(this.encryptionService.encryptText('isTruckUser'), (this.sharedService.isTruckUser).toString());
  }
}
