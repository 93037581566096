import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const appRoute: Routes = [
  { path: '', redirectTo: 'PCLCV', pathMatch: 'full' },
  {
    path: 'EXT/PCLCV',
    loadChildren: () => import('projects/package-portal-ext/src/app/app.module').then(m => m.AppModule)
  },
  {
    path: 'PCLCV',
    loadChildren: () => import('projects/package-portal/src/app/app.module').then(m => m.AppModule)
  },
  {
    path: 'EXT/TRUCK',
    loadChildren: () => import('projects/package-portal-ext/src/app/app.module').then(m => m.AppModule)
  },
  {
    path: 'TRUCK',
    loadChildren: () => import('projects/package-portal/src/app/app.module').then(m => m.AppModule)
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(appRoute, {
    initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
