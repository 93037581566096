import { Injectable } from "@angular/core";
import { ExportToDarsParamModel } from "../BusinessEntities/cart-total-count-view-model";
import { PackageCartRequestViewModel } from "../BusinessEntities/package-cart-view-model";
import { PartsOrderRequestModel } from "../BusinessEntities/parts-order-request-model";
import { PartsOrderUpdateViewModel } from "../BusinessEntities/parts-order-update-view-model";
import { CartDetailsDataService } from "../DataServices/cart-details-data.service";
import { PhoneNoViewModel } from "../BusinessEntities/phone-no-view-model";
import { ExternalUserDetailsViewModel } from "../BusinessEntities/external-user-details-view-model";


@Injectable({
  providedIn: 'root',
})
export class CartDetailsService {
  constructor(private cartDetailsDataService: CartDetailsDataService) {

  }
  async AddUpdateCartDetails(packageCartRequestModel: PackageCartRequestViewModel) {
    return await this.cartDetailsDataService.AddUpdateCartDetails(packageCartRequestModel);
  }
  async CreatePartsOrder(partsOrderRequestModel: PartsOrderRequestModel) {
    return await this.cartDetailsDataService.CreatePartsOrder(partsOrderRequestModel);
  }
  async UpdatePartsOrder(partsOrderUpdateModel: PartsOrderUpdateViewModel) {
    return await this.cartDetailsDataService.UpdatePartsOrder(partsOrderUpdateModel);
  }
  async GetWorkOrderById(workOrderId: string) {
    return await this.cartDetailsDataService.GetWorkOrderById(workOrderId);
  }
  async GetPackageCartDetails() {
    return await this.cartDetailsDataService.GetPackageCartDetails();
  }
  async GetPackagesForUpdate() {
    return await this.cartDetailsDataService.GetPackagesForUpdate();
  }
  async GetCartTotalCount() {
    return await this.cartDetailsDataService.GetCartTotalCount();
  }
  async GetAllSk2022Dealers() {
    return await this.cartDetailsDataService.GetAllSk2022Dealers();
  }
  async DeletePackageFromCart(packageId: number, isWholePackageRemove: boolean, isOnlyMaterial: boolean) {
    return await this.cartDetailsDataService.DeletePackageFromCart(packageId, isWholePackageRemove, isOnlyMaterial);
  }
  async DeleteAllPackageFromCart() {
    return await this.cartDetailsDataService.DeleteAllPackageFromCart();
  }
  async UpdateWorkOrderNumber(workOrderNumber: string) {
    return await this.cartDetailsDataService.UpdateWorkOrderNumber(workOrderNumber);
  }
  async ExportToDars(exportToDarsParam: ExportToDarsParamModel) {
    return await this.cartDetailsDataService.ExportToDars(exportToDarsParam);
  }
  async GetGroupById(id: number) {
    return await this.cartDetailsDataService.GetGroupById(id);
  }
  async GetCartPackages(packageCartRequestViewModels: PackageCartRequestViewModel[]) {
    return await this.cartDetailsDataService.GetCartPackages(packageCartRequestViewModels);
  }
  async SendOTP(phoneNoViewModel: PhoneNoViewModel) {
    return await this.cartDetailsDataService.SendOTP(phoneNoViewModel);
  }
  async ValidateOtpCode(mobileNo: string, otpCode: string) {
    return await this.cartDetailsDataService.ValidateOtpCode(mobileNo, otpCode);
  }
  async GetCustomerDetails(mobileNo: string) {
    return await this.cartDetailsDataService.GetCustomerDetails(mobileNo);
  }
  async SendExternalCartEmail(externalUserDetailsViewModel : ExternalUserDetailsViewModel) {
    return await this.cartDetailsDataService.SendExternalCartEmail(externalUserDetailsViewModel);
  }
  async GetExternalCartDetails(externalCartId : number) {
    if (isNaN(externalCartId)){
      return null;
    }
    return await this.cartDetailsDataService.GetExternalCartDetails(externalCartId);
  }
  async GetPreferredDealer(searchText : string) {
    return await this.cartDetailsDataService.GetPreferredDealer(searchText);
  }
  async AddSoldCount(packageId : number) {
    return await this.cartDetailsDataService.AddSoldCount(packageId)
  }
}
