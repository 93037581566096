export const GlobalConstant = {
    Grid: {
        PageSize: 25,
        StartRecordNo: 1
    },
    Toastr: {
        ExtendedTimeOut: 500,
        TimeOut: 2500,
        PositionClass: 'toast-top-right'
    },
    Dash: ' - ',
    Zero: '0',
    Space: ' ',
    OpenBracket: '(',
    ClosedBracket: ')',
    Colon: ' : ',
    Slash: '/',
    DateFormat: {
        Default: 'dd.MM.yyyy',
        UnderScoreSeparator: 'dd_MM_yyyy',
        Iso: 'yyyy-MM-dd',
        Slash: 'yyyy/MM/dd'
    },
    DateTimeFormat: {
        HypenSeparator: 'yyyy-MM-ddTHH.mm.ss',
        ZeroTimeStamp: 'yyyy-MM-ddT00:00:00',
        Colon: 'yyyy-MM-ddTHH:mm:ss',
        Default: 'dd.MM.yyyy HH:mm'
    },
    LocalStorage: {
        UserDetail: 'userDetail',
        CampaignUserManual: 'campaignUserManual',
        ReserveCarUserManualPath: 'reserveCarUserManualPath',
        OlpUserManualPath: 'olpUserManualPath',
        ToolTip: 'toolTip',
        AvailableRefreshTime: 1500,
        NotAvailableTime: 0
    },
    CommonSupplierNo: '9000300',
    AppointmentReleased: "AppointmentReleased",
    DefaultSppsPackageBrand: "MER,SMA,AMG"
};

export default GlobalConstant;

export const WeekNameConstant = {
    MondayEnglish: "Monday",
    MondayNorway: "Mandag",
    TuesdayEnglish: "Tuesday",
    TuesdayNorway: "Tirsdag",
    WednesdayEnglish: "Wednesday",
    WednesdayNorway: "Onsdag",
    ThursdayEnglish: "Thursday",
    ThursdayNorway: "Torsdag",
    FridayEnglish: "Friday",
    FridayNorway: "Fredag",
    SaturdayEnglish: "Saturday",
    SaturdayNorway: "Lørdag",
    SundayEnglish: "Sunday",
    SundayNorway: "Søndag"
}

export const FileFormat = {
    Extension: {
        Pdf: ".pdf",
        Excel: ".xlsx",
        Csv: ".csv",
        Xml:".xml"
    },
    ContentType: {
        Excel: "application/ms-excel",
        Pdf: "application/pdf",
        Csv: "text/csv"
    }
}

export const RackManagementConstant = {
    NormalArea: "DO Overflow Area",
    IntermediateArea: "Future Area",
    WarrantyArea: "Warranty Area",
    MechanicBayArea: "Mechanic Bay",
    CounterArea: "Counter Area",
    MaxNoOfBins: 12
}

export const WorkOrderStatusConstant = {
    WorkRequest: "WorkRequest",
    Prepared: "Prepared",
    Started: "Started",
    WorkDone: "WorkDone",
    Reported: "Reported",
    Finished: "Finished",
    Released: "Released",
    Cancelled: "Cancelled",
    MechanicDone: "MechanicDone"
}

export const ApplicationSourceConstant = {
    OLP: 1,
    IFS: 2,
    DARS: 22
}

export const CostType = {
    Personnel: 'Personnel',
    Material: 'Material',
    External: 'External'
};

export const CreateCustomerChannelTypeIfs = {
    Email: 'inf.001',
    Phone: 'inf.002',
    SMS: 'inf.003',
    Letter: 'inf.004'
};

export const CreateCustomerConsentsIfs = {
    Yes: 'YES',
    No: 'NO',
    NotReplied: 'NOT REPLIED'
};

export const PackageSource = {
    IFS: 1,
    SPPS: 2,
    DARS: 3
}

export const PSABrandCodes: string[] = [ 'PEU', 'CIT', 'DS' ]

export const DefaultConfigurationValues = {
    Year: 5,
    Mileage: 15000,
    UsageFactorPercentages: 0,
    PaymentPeriodMonths: 1 // -1 -> Full
}

export const SecurityKeyConst = {
  authApplicationSource : 'PackagePortal',
  intervalTimeForToken : 3300000 // Info: 1000ms (1 second) = 55 minutes
}

export const OutletPackage = {
  OutletBrandId:-2
}
