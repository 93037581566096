import { AfLoggingService } from '../../Autofacets.Infrastructure.Services/Af-Logging/af-logging.service';
import { environment } from 'src/environments/environment';
import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../Autofacets.Infrastructure.Services/base-service/base.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements ErrorHandler, HttpInterceptor {

  loggingService: AfLoggingService;
  baseService: BaseService;
  translateService: TranslateService;
  constructor(private injector?: Injector) {
  }

  error: any;
  handleError(error: any) {
    this.loggingService = this.injector.get(AfLoggingService);
    this.loggingService.LogError(error);
    if (!environment.production) {
      console.error(error);
      return throwError(error);
    }
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse) {
            this.baseService = this.injector.get(BaseService);
            this.translateService = this.injector.get(TranslateService);
            if (this.baseService.modalService.hasOpenModals()) {
              this.baseService.modalService.dismissAll();
            }
            if (this.baseService.toastr.toasts.length === 0) {
              if (error.status === 403) {
                this.baseService.toastr.warning(this.translateService.instant('UnknownUserMessage'));
              } else if (error.status === 404) {
                this.baseService.toastr.warning(this.translateService.instant('NotFoundUrlError'));
              } else if (error.headers.get('moduleifserror') != null){
                // for status code 500
                this.baseService.toastr.error(this.translateService.instant('IFSApiError'));
              }
            }
            this.baseService.toastr.toastrConfig.timeOut = 5000;
            return this.handleError(error);
          }
        })
      );
  }
}
