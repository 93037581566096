import { Injectable } from '@angular/core';
import { UserDataService } from './user-data.service';
@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private readonly userDataService: UserDataService) { }

  public async GetUserDetails() {
    return await this.userDataService.GetUserDetails();
  }

  public GetToken() {
    return this.userDataService.GetToken();
  }
}
